import React, { ReactNode } from "react";
import { Nav, NavItem } from "reactstrap";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { HashLink as Link } from "react-router-hash-link";

import "./Footer.scss";
import { Container, Row, Col } from "reactstrap";
import ColoredLine from "./ColoredLine";
import CookiesAlert from "./CookiesAlert";

const Footer = () => {
  return (
    // <!-- Footer -->
    <footer className="page-footer font-small indigo">
      {/* <!-- Footer Links --> */}
      <div className="container">
        {/* <!-- Grid row--> */}
        <div className="row text-center d-flex justify-content-center pt-5 mb-3">
          {/* <!-- Grid column --> */}
          <div className="col-md-2 mb-3">
            <h6 className="text-uppercase font-weight-bold">
              <NavLink
                smooth
                className="nav-link"
                to="/#story"
                activeClassName="selected"
              >
                Story
              </NavLink>
            </h6>
          </div>
          {/* <!-- Grid column --> */}
          <div className="col-md-2 mb-3">
            <h6 className="text-uppercase font-weight-bold">
              <NavLink
                smooth
                className="nav-link"
                to="/#menu"
                activeClassName="selected"
              >
                Menü
              </NavLink>
            </h6>
          </div>
          {/* <!-- Grid column --> */}

          {/* <!-- Grid column --> */}
          <div className="col-md-2 mb-3">
            <h6 className="text-uppercase font-weight-bold">
              <NavLink
                smooth
                className="nav-link"
                to="/#team"
                activeClassName="selected"
              >
                Team
              </NavLink>
            </h6>
          </div>
          {/* <!-- Grid column --> */}

          {/* <!-- Grid column --> */}
          <div className="col-md-2 mb-3">
            <h6 className="text-uppercase font-weight-bold">
              <NavLink
                smooth
                className="nav-link"
                to="/#takeaway"
                activeClassName="selected"
              >
                Take Away
              </NavLink>
            </h6>
          </div>
          {/* <!-- Grid column --> */}

          {/* <!-- Grid column --> */}
          <div className="col-md-2 mb-3">
            <h6 className="text-uppercase font-weight-bold">
              <NavLink
                smooth
                className="nav-link"
                to="/#gutscheine"
                activeClassName="selected"
              >
                Gutscheine
              </NavLink>
            </h6>
          </div>
          {/* <!-- Grid column --> */}
          <div className="col-md-2 mb-3">
            <h6 className="text-uppercase font-weight-bold">
              <NavLink
                smooth
                className="nav-link"
                to="/#festsaal"
                activeClassName="selected"
              >
                Festsaal
              </NavLink>
            </h6>
          </div>
          {/* <!-- Grid column --> */}
          <div className="col-md-2 mb-3">
            <h6 className="text-uppercase font-weight-bold">
              <NavLink
                smooth
                className="nav-link"
                to="/#reservierung"
                activeClassName="selected"
              >
                Reservierung
              </NavLink>
            </h6>
          </div>
          {/* <!-- Grid column --> */}
          <div className="col-md-2 mb-3">
            <h6 className="text-uppercase font-weight-bold">
              <NavLink
                smooth
                className="nav-link"
                to="/#gallery"
                activeClassName="selected"
              >
                Galerie
              </NavLink>
            </h6>
          </div>
          {/* <!-- Grid column --> */}
        </div>
        {/* <!-- Grid row--> */}
        {/* <hr className="rgba-white-light" style={{margin: 25}}> </hr> */}

        <ColoredLine color="grey" />

        {/* <!-- Grid row--> */}
        <div className="row d-flex text-center justify-content-center m-2 m-md5">
          {/* <div
            id="business-hours"
            className="col-md-6 col-12 mx-auto mt-5 business-hours"
          >
            <p>
              <i className="fa fa-clock-o fa-4x" />
            </p>
            <h3 className="text-uppercase mb-4 font-weight-bold">
              Öffnungszeiten
            </h3>

            <dl className="row ">
              <dt className="col-sm-6">Montag</dt>
              <dd className="col-sm-6">Ruhetag</dd>
              <dt className="col-sm-6">Dienstag</dt>
              <dd className="col-sm-6">18:00 - 23:00 Uhr</dd>
              <dt className="col-sm-6">Mittwoch</dt>
              <dd className="col-sm-6">
                11:30 - 14:30 Uhr und 18:00 - 23:00 Uhr
              </dd>
              <dt className="col-sm-6">Donnerstag</dt>
              <dd className="col-sm-6">18:00 – 23:00 Uhr</dd>
              <dt className="col-sm-6">Freitag - Sonntag & Feiertage</dt>
              <dd className="col-sm-6">
                11:30 - 14:30 Uhr und 18:00 - 23:00 Uhr
              </dd>
            </dl>
          </div> */}

          {/* <!-- Grid column --> */}
          <div id="contact" className="col-md-6 col-12 mx-auto my-5 contact">
            <p>
              <i className="fa fa-envelope fa-4x" />
            </p>
            <h3 className="text-uppercase mb-4 font-weight-bold">Kontakt</h3>
            <div className="row ">
              <div className="col-sm-12 adress">
                <p>Restaurant Orchideen Garten</p>
                <p>Herr Quang Binh Tran</p>
                <p>Spiegelstraße 7</p>
                <p>32361 Preußisch Oldendorf</p>
                <p>
                  <a
                    href="mailto:kontakt@orchideen-garten.de?Subject=Reservierung"
                    target="_top"
                  >
                    <i className="fa fa-envelope-o" />{" "}
                    kontakt@orchideen-garten.de
                  </a>
                </p>
                <p>
                  <a href="tel:+ 4957424361">
                    <i className="fa fa-phone" /> + 49 5742 4361
                  </a>
                </p>
              </div>
              <div className="col-sm-12">
                <p>
                  Kontaktieren Sie uns gerne, wenn Sie ein Anliegen haben oder
                  eine Tisch-Reservierung anfragen möchten.
                </p>
              </div>
            </div>
          </div>
          {/* <!-- Grid column --> */}
        </div>

        {/* <!-- Grid row--> */}
        <ColoredLine color="grey" />

        {/* <!-- Grid row--> */}
        <div className="row pb-3">
          {/* <!-- Grid column --> */}
          <div className="col-md-12">
            <div className="mb-5 flex-center">
              {/* <!-- Facebook --> */}
              <a className="fb-ic">
                <i className="fa fa-facebook fa-lg white-text mr-4"> </i>
              </a>

              {/* <!--Instagram--> */}
              <a className="ins-ic">
                <i className="fa fa-instagram fa-lg white-text mr-4"> </i>
              </a>
            </div>
          </div>
          {/* <!-- Grid column --> */}
        </div>
      </div>
      {/* <!-- Footer Links --> */}

      {/* <!-- Copyright --> */}
      <div className="footer-copyright text-center py-3">
        © 2018 Copyright:
        <NavLink
          scroll={el =>
            el.scrollIntoView({ behavior: "smooth", block: "start" })
          }
          to="/#top"
          activeClassName="selected"
        >
          Orchideen Garten GmbH
        </NavLink>
        <Link
          to="/impressum#top"
          scroll={el =>
            el.scrollIntoView({ behavior: "smooth", block: "start" })
          }
        >
          {" "}
          Impressum
        </Link>
        <Link
          to="/datenschutz#top"
          scroll={el =>
            el.scrollIntoView({ behavior: "smooth", block: "start" })
          }
        >
          {" "}
          Datenschutz
        </Link>
      </div>
      {/* <!-- Copyright --> */}
    </footer>
    //   {/* <!-- Footer --> */}
  );
};
export default Footer;
