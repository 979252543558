import React, { Component } from "react";
import "./Home.scss";
import { Container, Row, Col, Button, Jumbotron } from "reactstrap";
import Images from "../utils/Images";
import ColoredLine from "../components/ColoredLine";
import CookiesAlert from "../components/CookiesAlert";
import CallButton from "../components/CallButton";

const stempelStyle: any = {
  position: "absolute",
  bottom: "-50px",
  left: "-50px",
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  width: "300px"
};

const CallButtonWrapper: any = {
  position: "fixed",
  bottom: 40,
  right: 40,
  zIndex: 2000
  /* box-shadow: 2 2 3 #999; */
};

interface Homeprops {
  user: string;
}

interface IAppState {
  isOpen: any;
}

class Home extends Component<Homeprops, IAppState> {
  constructor(props: any) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div>
        <div
          id="top"
          style={{ position: "absolute", top: "-100vh", left: 0 }}
        />
        <div className="og-header-img">
          {/* <img style={stempelStyle} src={Images.stempel.src} /> */}
        </div>
        <div className="og-header-text">
          <h2>Herzlich Willkommen!</h2> <ColoredLine color="#d3<9e00" />
          <h1 style={{ fontFamily: "Hind" }}>Restaurant Orchideen-Garten </h1>
        </div>
        <CallButton color="light" />

        <Container fluid className="no-padding">
          <Row noGutters className="align-items-center " id="story">
            <Col className="og-section-text">
              <div className="og-section-title">
                <h2 className="display-4">Unsere Geschichte</h2>
              </div>{" "}
              <p>
                Das Restaurant „Orchideen-Garten“ ist ein Familienbetrieb und
                Wegbereiter der chinesischen und vietnamesischen Garküche in
                Preußisch Oldendorf. Seit der Eröffnung im Jahre 2005 folgen wir
                der bekannten fernöstlichen Devise „Der Kunde ist König“. Diesem
                Motto entsprechend bereiten wir nicht nur die köstlichen Speisen
                meisterhaft für Sie zu, sondern wir verwenden nur erstklassige
                Zutaten, die sich im unverfälschtem Geschmack widerspiegeln. Wir
                kochen mit viel Liebe für Gäste und Freunde in einer Atmosphäre,
                die schöne Erinnerungen schafft. Kommen Sie vorbei und genießen
                Sie. Egal, ob Sie nur kurz Zeit haben, oder ein wenig länger.
                Genießen hat kein Zeitlimit. Familie Tran freut sich über Ihren
                Besuch. In diesem Sinne heißen wir Sie herzlich willkommen und
                wünschen Ihnen einen angenehmen Aufenthalt in unserem Restaurant
                Orchideen-Garten in Preußisch Oldendorf.
              </p>
            </Col>
          </Row>
          <Row noGutters className="align-items-center story">
            <Col className="column">
              {" "}
              <img src={Images.story.src} />
            </Col>

            <Col className="column">
              <img src={Images.story_2.src} />
            </Col>
            <Col className="column">
              <img src={Images.story_3.src} />
            </Col>
          </Row>

          <Row noGutters className="align-items-center">
            <Col md="6" className="og-section-text col-section">
              <div className="og-section-title">
                <h2>Frische Zutaten </h2>
              </div>
              <p>
                Lassen sie sich verwöhnen und genießen Sie die vielfältigen
                Aromen aus den tropischen Gebieten Südostasiens. Erleben Sie den
                Duft von Curry, Zitronengras und geröstetem Sesam. Alle Gerichte
                bereiten wir nach Originalrezept mit frischen Zutaten aromatisch
                für Sie zu. Das „Orchideen-Garten“ bietet ausgewählte Gerichte
                auf einer exklusiven Speisekarte an, welche schon beim
                Durchblättern auf die Frische und Qualität der Produkte
                schließen lässt.
              </p>
            </Col>
            <Col md="6">
              <div className="og-image-v menu" />
            </Col>
          </Row>
          <Row noGutters className="align-items-center" id="menu">
            <Col sm="12" className="og-section-text og-menu  col-section">
              <div className="og-section-title">
                <h2>Unser Menü</h2>
              </div>
              <p>
                Im Orchideen-Garten bieten wir Ihnen besonders ausgewählte
                Köstlichkeiten aus der reichhaltigen vietnamesischen,
                chinesischen und thailändischen Küche an. Die Rezepte unseres
                Restaurants stammen aus einer langen Familientradition. Schon
                die Urgroßmutter des Geschäftsführers bekochte die Familie mit
                raffinierten Rezepten aus der französischen Kolonialzeit..
              </p>

              <br />
              <div className="og-section-btn">
                <Button outline color="light" size="lg" href="../menu/menu.pdf">
                  zur Speisekarte
                </Button>{" "}
              </div>
            </Col>
          </Row>

          <Row noGutters className="align-items-center" id="team">
            <Col md="4">
              <div className="og-image-v team-1" />
            </Col>
            <Col md="4" className="og-section-text  col-section">
              <div className="og-section-title">
                <h2>Unser Team </h2>
              </div>
              <p>
                Das Herzstück eines jeden Restaurants ist ein gut
                funktionierendes Team, so auch im Orchideen-Garten. Mit
                vietnamesischer Gastfreundlichkeit, über Generationen hinweg
                gesammeltes Know-how der traditionellen asiatischen Küche sowie
                Pfiff und viel Liebe zum Detail begeistert das
                „Orchideen-Garten“ seine Gäste. Dabei sorgt das ganze Team
                dafür, dass Sie sich rundum wohl fühlen und keine Wünsche offen
                bleiben.
              </p>
            </Col>
            <Col md="4">
            <div className="og-image-v team-2" />
               </Col>
          </Row>
          <Row noGutters className="align-items-center" id="catering">
            <Col md="4" className="og-section-text  col-section">
              <div className="og-section-title">
                <h2>Catering</h2>
              </div>{" "}
              <p>
                Für kleine bis mittlere Veranstaltungen richten wir Ihnen gerne
                ein individuelles passendes Buffet ein. Bitte sprechen Sie uns
                an damit wir alles Weitere besprechen und planen können.
              </p>
            </Col>
            <Col md="8">
              <img
                className="img-fluid"
                srcSet={Images.catering.srcSet}
                src={Images.catering.src}
              />
            </Col>
          </Row>
          <Row noGutters className="align-items-center " id="takeaway">
            <Col md="8">
              <img
                className="img-fluid"
                srcSet={Images.take_away.srcSet}
                src={Images.take_away.src}
              />
            </Col>
            <Col md="4" className="og-section-text col-section">
              <div className="og-section-title">
                <h2>Take Away</h2>
              </div>
              <p>
                Sie wollen manchmal lieber im Büro oder gemütlich zu Hause
                essen. Natürlich können Sie die meisten unserer Speisen auch zum
                Mitnehmen „Außer-Haus“ genießen.
              </p>
            </Col>
          </Row>
          <div className="d-md-none">
            <Row noGutters className="align-items-center hidden-sm-up">
              <Col>
                <ColoredLine color="#d39e00" />
              </Col>
            </Row>
          </div>

          <Row noGutters className="align-items-center" id="gutscheine">
            <Col md="4" className="og-section-text col-section">
              <div className="og-section-title">
                <h2>Gutscheine</h2>
              </div>{" "}
              <p>
                Überraschen Sie ihre Liebsten mit einem Gutschein vom
                Orchideen-Garten. Dieses kulinarische Geschenk ist schön
                verpackt in einem vietnamesischen Schmuckumschlag.
              </p>
            </Col>
            <Col md="8">
              <img
                className="img-fluid"
                srcSet={Images.gutscheine.srcSet}
                src={Images.gutscheine.src}
              />
            </Col>
          </Row>

          <Row noGutters className="align-items-center" id="festsaal">
          <Col md="3">
              <img
                className="img-fluid"
                srcSet={Images.festsaal_1.srcSet}
                src={Images.festsaal_1.src}
              />
            </Col>
            <Col md="4" className="og-section-text col-section">
              <div className="og-section-title">
                <h2>Festsaal</h2>
              </div>{" "}
              <p>
              Im Orchideen-Garten können Sie nun auch unseren neuen modernen Festsaal für 40 bis zu 75 Personen mieten. Ausgestattet mit einer schönen erhöhten Bühne, Redepult, eigenem Thekenbereich und Beamer ist diese Räumlichkeit für aller Art besonderer Anlässe geeignet. Planen Sie eine Hochzeit, einen Geburtstag, Konfirmationsfeier oder Firmenfeier/-tagungen? Den Saal alleine können Sie mieten und sich mit eigenen Speisen sowie Getränken versorgen. Auf Wunsch richten wir für Sie ein individuelles Buffet im separaten Nebenraum ein. Sprechen Sie uns gerne, wir beraten und gestalten nach Ihren Wünschen.
              </p>
            </Col>
            <Col md="5">
              <img
                className="img-fluid"
                srcSet={Images.festsaal_2.srcSet}
                src={Images.festsaal_2.src}
              />
            </Col>
          </Row>

          <Row noGutters className="align-items-center" id="reservierung">
            <Col md="8">
              <img
                className="img-fluid"
                srcSet={Images.reservierung.srcSet}
                src={Images.reservierung.src}
              />
            </Col>
            <Col md="4" className="og-section-text col-section">
              <div className="og-section-title">
                <h2>Reservierung</h2>
              </div>
              <h3>Immer für Sie da</h3>{" "}
              <p>
                Wenn Sie einen Tisch reservieren oder ein Angebot für
                Feierlichkeiten anfordern möchten, zögern Sie nicht uns zu
                kontaktieren. Gern reservieren wir einen Tisch für Sie,
                unterbreiten Ihnen ein unverbindliches Angebot für Ihre
                Feierlichkeiten oder stehen Ihnen bei Fragen gerne zur
                Verfügung.
              </p>
              <p>
                <a
                  className="btn btn-link btn-lg btn-block"
                  href="tel:+ 4957424361"
                >
                  <i className="fa fa-phone" /> + 49 5742 4361
                </a>
              </p>
              <p>
                <a
                  className="btn btn-link btn-block"
                  href="mailto:kontakt@orchideen-garten.de?Subject=Reservierung"
                  target="_top"
                >
                  <i className="fa fa-envelope-o" /> kontakt@orchideen-garten.de
                </a>
              </p>
            </Col>
          </Row>

          <Row noGutters className="gallery">
            <Col xs="12" id="gallery" className="og-section-text">
              <div className="og-section-title">
                <h2 className="display-4">Galerie</h2>
              </div>{" "}
              <ColoredLine color="#d39e00" />
            </Col>

            <Col className="column">
              <img src={Images.gallery.food_1.src} />
              <img src={Images.gallery.food_2.src} />
              <img src={Images.gallery.food_3.src} />
              <img src={Images.gallery.rest_1.src} />
              <img src={Images.buffet_eingang.src} />
            </Col>
            <Col className="column">
              <img src={Images.gallery.table_4.src} />
              <img src={Images.gallery.table_5.src} />
              <img src={Images.gallery.table_6.src} />
              <img src={Images.gallery.table_7.src} />
              <img src={Images.gallery.food_5.src} />
              <img src={Images.buffet_1200.src} />
            </Col>
            <Col className="column">
              <img src={Images.gallery.food_4.src} />
              <img src={Images.gallery.rest_4.src} />
              <img src={Images.gallery.rest_10.src} />
              <img src={Images.gallery.kitchen_4.src} />
              <img src={Images.festsaal_aussen_2.src} />
            </Col>
            <Col className="column">
              <img src={Images.gallery.rest_6.src} />
              <img src={Images.gallery.table_2.src} />
              <img src={Images.gallery.table_3.src} />
              <img src={Images.gallery.rest_5.src} />
              <img src={Images.restaurant.src} />
            </Col>
          </Row>
        </Container>
        {/* <CookiesAlert color="info"></CookiesAlert> */}
      </div>
    );
  }
}

export default Home;
