import React, { Component } from "react";
import Navbar from "reactstrap/lib/Navbar";
import NavbarBrand from "reactstrap/lib/NavbarBrand";
import NavbarToggler from "reactstrap/lib/NavbarToggler";
import Collapse from "reactstrap/lib/Collapse";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import { NavHashLink as NavLink } from "react-router-hash-link";
import Images from "../utils/Images";
import NavBarItem from "./NavBarItem";

interface IAppState {
  isOpen: any;
}

const NavBarTitleStyle = {
  marginTop: 0,
  color: "#d39e00",
  fontFamily: "Parisienne",
  alignItems: "center"
};

const NavBarFullScreenStyle: React.CSSProperties = {
  height: "90vh",
  fontWeight: 500,
  fontFamily: "Hind"
};

const ButtonRowStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-around",
  flexWrap: "wrap",
  padding: 4
};
const ButtonColumnStyle = {
  flexGrow: 1,
  flex: "33%",
  maxWidth: "33%"
};

class NavBar extends Component<{}, IAppState> {
  constructor(props: any) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    let navbarColor = "navbar-standard";
    if (this.state.isOpen) {
      navbarColor = "navbar-blur  p-lg-3";
    } else {
      navbarColor = "navbar-standard p-lg-3";
    }

    return (
      <div>
        <Navbar color="" className={navbarColor} dark fixed="top">
          <NavbarBrand href="/" className="p-md-1 p-lg-1">
            <img
              height="100%"
              src={Images.logo}
              className="App-logo"
              alt="logo"
            />
          </NavbarBrand>
          <p style={NavBarTitleStyle}>Orchideen Garten </p>
          <NavbarToggler onClick={this.toggle} className="mr-1 no-border" />

          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto mx-auto" navbar onClick={this.toggle}>
              <div
                style={{ ...NavBarFullScreenStyle, ...ButtonRowStyle }}
                className="container-button text-center"
              >
                <div style={ButtonColumnStyle}>
                  <NavBarItem to="/#story" icon="home" name="Story" />
                  <NavBarItem to="/#menu" icon="cutlery" name="Menü" />
                  <NavBarItem to="/#team" icon="users" name="Team" />
                </div>
                <div style={ButtonColumnStyle}>
                  <NavBarItem
                    to="/#takeaway"
                    icon="hand-rock-o"
                    name="Take Away"
                  />
                  <NavBarItem to="/#gutscheine" icon="gift" name="Gutscheine" />
                  <NavBarItem
                    to="/#reservierung"
                    icon="calendar"
                    name="Reservierung"
                  />
                </div>
                <div style={ButtonColumnStyle}>
                  <NavBarItem to="/#gallery" icon="picture-o" name="Gallerie" />
                  <NavBarItem to="/#contact" icon="envelope" name="Kontakt" />
                  <NavBarItem
                    to="/#business-hours"
                    icon="clock-o"
                    name="Zeiten"
                  />
                </div>
              </div>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
export default NavBar;
