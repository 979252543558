import React, { Component } from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import { Header, Footer } from "./components";

class App extends Component<{}, {}> {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <div>
            <Header />
            <Routes />
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
