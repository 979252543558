import React from "react";

import NavItem from "reactstrap/lib/NavItem";
import { NavHashLink as NavLink } from "react-router-hash-link";

type NavBarItemProps = {
  to: string;
  icon: string;
  name: string;
};

const NavLinkStyle: React.CSSProperties = {
  paddingTop: 20,
  paddingBottom: 20,
  verticalAlign: "middle",
  textAlign: "center"
};

const NavBarItem = (props: NavBarItemProps) => (
  <NavItem style={NavLinkStyle}>
    <NavLink smooth to={props.to} activeClassName="selected">
      <span className="fa-stack ">
        <i className="fa fa-circle-thin fa-stack-2x" />
        <i className={"fa fa-" + props.icon + " fa-stack-1x"} />
      </span>
      <p>{props.name}</p>
    </NavLink>
  </NavItem>
);

export default NavBarItem;
