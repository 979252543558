import React, { Component } from "react";

import "./Header.scss";
import NavBar from "./NavBar";

interface IAppState {
  isOpen: any;
}

class Header extends Component<{}, IAppState> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div>
        <header className="App-header">

          {/* <Hidden only="xs"><NavBar /></Hidden> */}
          <NavBar />
        </header>
      </div>
    );
  }
}
export default Header;
