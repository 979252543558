const images = {
  titel: require("../assets/images/titel_1.jpg"),
  team_1: require("../assets/images/team_1.jpg"),
  team_2: require("../assets/images/team_2.jpg"),
  gutscheine: require("../assets/images/gutscheine.jpg"),
  festsaal_1: require("../assets/images/festsaal_2-1125.jpg"),
  festsaal_2: require("../assets/images/festsaal_1-2000.jpg"),
  festsaal_aussen_2: require("../assets/images/festsaal_aussen-1200.jpg"),
  restaurant: require("../assets/images/restaurant-1200.jpg"),
  buffet_eingang: require("../assets/images/buffet_eingang-960.jpg"),
  buffet_1200: require("../assets/images/buffet-1200.jpg"),
  story_2: require("../assets/images/titel_2.jpg"),
  story_3: require("../assets/images/story_3.jpg"),
  story: require("../assets/images/story.jpg"),
  reservierung: require("../assets/images/reservierung.jpg"),
  take_away: require("../assets/images/take_away.jpg"),
  catering: require("../assets/images/catering.jpg"),
  menu: require("../assets/images/menu.jpg"),
  logo: require("../orchid.png"),
  stempel: require("../assets/images/stempel.png"),
  gallery: {
    bar_1: require("../assets/images/Gallerie/bar_1.jpg"),
    bar_2: require("../assets/images/Gallerie/bar_2.jpg"),
    food_1: require("../assets/images/Gallerie/food_1.jpg"),
    food_2: require("../assets/images/Gallerie/food_2.jpg"),
    food_3: require("../assets/images/Gallerie/food_3.jpg"),
    food_4: require("../assets/images/Gallerie/food_4.jpg"),
    food_5: require("../assets/images/Gallerie/food_5.jpg"),
    food_6: require("../assets/images/Gallerie/food_6.jpg"),
    kitchen_1: require("../assets/images/Gallerie/kitchen_1.jpg"),
    kitchen_2: require("../assets/images/Gallerie/kitchen_2.jpg"),
    kitchen_3: require("../assets/images/Gallerie/kitchen_3.jpg"),
    kitchen_4: require("../assets/images/Gallerie/kitchen_4.jpg"),
    rest_1: require("../assets/images/Gallerie/rest_1.jpg"),
    rest_2: require("../assets/images/Gallerie/rest_2.jpg"),
    rest_3: require("../assets/images/Gallerie/rest_3.jpg"),
    rest_4: require("../assets/images/Gallerie/rest_4.jpg"),
    rest_5: require("../assets/images/Gallerie/rest_5.jpg"),
    rest_6: require("../assets/images/Gallerie/rest_6.jpg"),
    rest_7: require("../assets/images/Gallerie/rest_7.jpg"),
    rest_8: require("../assets/images/Gallerie/rest_8.jpg"),
    rest_9: require("../assets/images/Gallerie/rest_9.jpg"),
    rest_10: require("../assets/images/Gallerie/rest_10.jpg"),
    table_1: require("../assets/images/Gallerie/table_1.jpg"),
    table_2: require("../assets/images/Gallerie/table_2.jpg"),
    table_3: require("../assets/images/Gallerie/table_3.jpg"),
    table_4: require("../assets/images/Gallerie/table_4.jpg"),
    table_5: require("../assets/images/Gallerie/table_5.jpg"),
    table_6: require("../assets/images/Gallerie/table_6.jpg"),
    table_7: require("../assets/images/Gallerie/table_7.jpg")
  }
};

export default images;
