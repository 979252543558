import React from "react";

type LineProps = {
  color: any;
};

const ColoredLine = (props: LineProps) => (
  <hr
    className="clearfix rgba-white-light"
    style={{
      margin: 25,
      color: props.color,
      backgroundColor: props.color,
      height: 1
    }}
  />
);

export default ColoredLine;
