import React from "react";
import Button from "reactstrap/lib/Button";
import posed from "react-pose";
import styled from "styled-components";

type CallButtonProps = {
  color: any;
};

const Circle = posed.div({
  attention: {
    scale: 1.2,
    transition: {
      type: "spring",
      stiffness: 200,
      damping: 0
    }
  }
});

const StyledCircle = styled(Circle)`
  width: 60px;
  height: 60px;
  background-color: #d39e00;
  border-radius: 50%;
  text-align: "center";
`;
const CallButtonStyle: any ={
  width: 60,
  height: 60,
  backgroundColor: 'none',
  borderRadius: '50%',
  border: 0,
  textAlign: "center",
}
const CallButtonWrapper: any = {
  position: "fixed",
  bottom: 40,
  right: 40,
  zIndex: 2000
  /* box-shadow: 2 2 3 #999; */
};

const IconStyle: any = {
  marginTop: 12
};

const CallButton = (props: CallButtonProps) => (
  <div style={CallButtonWrapper}>
    <StyledCircle>
       <Button
      style={CallButtonStyle}
      className=""
      outline
      color={props.color}
      size="lg"
      href="tel:+ 4957424361"
    >
      <i style={IconStyle} className="fa fa-phone" />
    </Button>
    </StyledCircle>
   
  </div>
);

export default CallButton;


