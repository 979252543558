import * as React from "react";
import "./Impressum.scss";
import ColoredLine from "../components/ColoredLine";
import styled from "styled-components";

interface ImpressumProps {
  user: string;
}

const containerStyle: React.CSSProperties = {
textAlign: "left", paddingLeft: "10vw", paddingRight: '10vw'}

const Impressum = (props: ImpressumProps) => (
  <div>
    <div id="top" style={{ position: "absolute", top: "-100vh", left: 0 }} />
    <div className="og-header" />
    <div className="og-header-title">
      <h1> IMPRESSUM</h1> <ColoredLine color="#d39e00" />
      <h1>Kontaktinformationen </h1>
    </div>

    <div id="kontakt" className="bs-docs-section">
      <div>
        <h3>Angaben gemäß § 5 TMG:</h3>

<div style={containerStyle}>
 <strong>Inhaber: Quang Binh Tran</strong>
        <br />
        Restaurant Orchideen-Garten
        <br />
        Spiegel Stra&szlig;e 7
        <br />
        32361 Preu&szlig;isch Oldendorf
        <br /><br />
       
        <strong>Kontakt</strong>
        <br></br>
         <abbr title="Email-Adresse">E-Mail:</abbr>{" "}
        <a href="mailto:quang.binh@t-online.de">quang.binh@t-online.de</a>
        <br />
        <abbr title="Telefonnummer">Telefon:</abbr> + 49 (0) 5742 - 4361
        <br />
        <abbr title="Telefax">Telefon:</abbr> + 49 (0) 5742 - 701922

</div>
<br /><br />
        
        <h3>Umsatzsteuer-ID gemäß §27 a Umsatzsteuergesetz:</h3>
      
        <div style={containerStyle}>
       

<br />
<strong>DE249060102</strong>
<br /> <br />
Aufsichtsbehörde: <br />
Stadt Preußisch Oldendorf <br /> <br />
Angaben zur Berufshaftpflichtversicherung: <br />
Name und Sitz der Gesellschaft: <br />
Allianz Versicherungs AG <br />
München <br /> <br />
Geltungsraum der Versicherung: Deutschland <br />
Quelle: http://www.e-recht24.de <br /> <br />
Der Unternehmer verpflichtet sich nicht, an Streitbeilegungsverfahren vor einer <br />
Verbraucherschlichtungsstelle teilzunehmen. <br />
        </div> 
      </div>
    </div>
  </div>
);

export default Impressum;
